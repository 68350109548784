import { useRef, useEffect, useState } from 'react';
import useApi from '../useApi';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../config.json';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import BounceLoader from 'react-spinners/BounceLoader';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';

const inputTypes = {
    text: 'text',
    email: 'email',
    telephone: 'tel',
    select: 'select',
    textarea: 'textarea'
};

const defaultFormFieldValues = {
    recaptchaToken: null,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    address2: '',
    city: 'Aurora',
    state: 'IL',
    zipCode: '',
    comments: ''
};

const YardSignForm = () => {
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('Aurora');
    const [state, setState] = useState('IL');
    const [zipCode, setZipCode] = useState('');
    const [comments, setComments] = useState('');
    const [showError, setShowError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const recaptchaRef = useRef(null);

    const isMobile = useMediaQuery({ maxWidth: '1024px' });

    const location = useLocation();
    const lastHash = useRef('');

    useEffect(() => {
        if (location.hash) {
          lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
        }
    
        if (lastHash.current && document.getElementById(lastHash.current)) {
          setTimeout(() => {
            document
              .getElementById(lastHash.current)
              ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
            lastHash.current = '';
          }, 100);
        }
      }, [location]);

    const resetForm = () => {
        document.getElementById('yard-sign-form').reset();
        setRecaptchaToken(defaultFormFieldValues['recaptchaToken']);
        setFirstName(defaultFormFieldValues['firstName']);
        setLastName(defaultFormFieldValues['lastName']);
        setEmail(defaultFormFieldValues['email']);
        setPhone(defaultFormFieldValues['phone']);
        setAddress(defaultFormFieldValues['address']);
        setAddress2(defaultFormFieldValues['address2']);
        setCity(defaultFormFieldValues['city']);
        setState(defaultFormFieldValues['state']);
        setZipCode(defaultFormFieldValues['zipCode']);
        setComments(defaultFormFieldValues['comments']);
        recaptchaRef.current?.reset();
    };

    const handleSubmitForm = (e) => {
        e.preventDefault();

        const isValid = validateForm();

        if (isValid) {
            setShowError(false);

            fetch(`${config['apiBaseUrl']}/request-yard-sign`, {
                method: 'POST', body: JSON.stringify({
                    captchaToken: recaptchaToken,
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    phone: phone,
                    address: address,
                    address2: address2,
                    city: city,
                    state: state,
                    zipCode: zipCode,
                    comments: comments
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => {
                    resetForm();
                    toast.success('Request sent');
                    setIsLoading(false);
                })
                .catch((error) => {
                    toast.error('Request failed. Please try sending again or email keithforaurora@gmail.com if this issue continues.');
                    setIsLoading(false);
                });

            setIsLoading(true);
        } else {
            setShowError(true);
            toast.error('Please fill out all the required fields before submitting');
        }
    };

    const isEmpty = (val) => {
        return !val || val.length === 0;
    };

    const validateForm = () => {
        const isInvalid = isEmpty(firstName) ||
            isEmpty(lastName) ||
            (isEmpty(email) && isEmpty(phone)) ||
            isEmpty(address) ||
            isEmpty(city) ||
            isEmpty(state) ||
            isEmpty(zipCode) ||
            isEmpty(recaptchaToken);

        return !isInvalid;
    };

    const onRecaptchaChange = (value) => {
        setRecaptchaToken(value);
    };

    const isInputErrored = (value) => {
        return showError && isEmpty(value);
    };

    return <>
        <span id="yard-sign-information" />
        <div className="yard-sign-information">
            <h1>Request a Yard Sign!</h1>
            <p>
                My yard signs are now available! Yard signs are an important way for me to get my name out there
                and build name recognition. If you would like to help with this, you can request a yard sign using
                the form below. Once you submit the form, we will work on getting your sign put in soon. I greatly
                appreciate all the support, and yard signs are one way you can support me without spending
                any money.
            </p>
        </div>
        <form id="yard-sign-form" className={`yard-sign-form-wrapper`}>
            {isLoading && <BounceLoader color="#287f24" isLoading={isLoading} cssOverride={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />}
            <div className={`${isLoading ? 'loading' : ''}`}>
                <FormFieldSetLegend label="Name" id="ys-name" fieldsetClass="two-input-group" isRequired showIsRequired>
                    <FormInputLabel
                        label="First Name"
                        inputType={inputTypes.text}
                        id="ys-first-name"
                        containerClass="half-width"
                        value={firstName}
                        onChange={setFirstName}
                        errored={isInputErrored(firstName)}
                        isRequired
                        showIsRequired={false}
                        isLoading={isLoading}
                    />
                    <FormInputLabel
                        label="Last Name"
                        inputType={inputTypes.text}
                        id="ys-last-name"
                        containerClass="half-width"
                        value={lastName}
                        onChange={setLastName}
                        errored={isInputErrored(lastName)}
                        isRequired
                        showIsRequired={false}
                        isLoading={isLoading}
                    />
                </FormFieldSetLegend>
                <FormFieldSetLegend label="Contact Info" id="ys-contact" fieldsetClass="two-input-group" ariaLabel="At least one of these fields must be provided" isRequired showIsRequired isOneRequired>
                    <FormInputLabel
                        label="Email"
                        inputType={inputTypes.email}
                        id="ys-email"
                        containerClass="half-width"
                        value={email}
                        onChange={setEmail}
                        errored={isInputErrored(email)}
                        showIsRequired={false}
                        isLoading={isLoading}
                    />
                    <FormInputLabel
                        label="Phone"
                        inputType={inputTypes.telephone}
                        id="ys-phone"
                        containerClass="half-width"
                        value={phone}
                        onChange={setPhone}
                        errored={isInputErrored(phone)}
                        showIsRequired={false}
                        isLoading={isLoading}
                    />
                </FormFieldSetLegend>
                <FormFieldSetLegend label="Address" id="ys-address" fieldsetClass="address-group" isRequired showIsRequired>
                    <FormInputLabel
                        label="Street Address"
                        inputType={inputTypes.text}
                        id="ys-street-address"
                        containerClass="address-input"
                        value={address}
                        onChange={setAddress}
                        errored={isInputErrored(address)}
                        isRequired
                        showIsRequired={false}
                        isLoading={isLoading}
                    />
                    <FormInputLabel
                        label="Address Line 2"
                        inputType={inputTypes.text}
                        id="ys-street-address-2"
                        containerClass="address-input"
                        value={address2}
                        onChange={setAddress2}
                        errored={isInputErrored(address2)}
                        showIsRequired={false}
                        isLoading={isLoading}
                    />
                    <FormInputLabel
                        label="City"
                        inputType={inputTypes.text}
                        id="ys-city"
                        containerClass="address-input"
                        value={city}
                        onChange={setCity}
                        errored={isInputErrored(city)}
                        isRequired
                        showIsRequired={false}
                        isLoading={isLoading}
                    />
                    <FormInputLabel
                        label="State"
                        inputType={inputTypes.select}
                        id="ys-state"
                        containerClass="address-input"
                        value={state}
                        onChange={setState}
                        errored={isInputErrored(state)}
                        isRequired
                        showIsRequired={false}
                        isLoading={isLoading}
                    >
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="DC">District Of Columbia</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="ID">Idaho</option>
                        <option value="IL" selected>Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="IA">Iowa</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="ME">Maine</option>
                        <option value="MD">Maryland</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MS">Mississippi</option>
                        <option value="MO">Missouri</option>
                        <option value="MT">Montana</option>
                        <option value="NE">Nebraska</option>
                        <option value="NV">Nevada</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NY">New York</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VT">Vermont</option>
                        <option value="VA">Virginia</option>
                        <option value="WA">Washington</option>
                        <option value="WV">West Virginia</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WY">Wyoming</option>
                    </FormInputLabel>
                    <FormInputLabel
                        label="Zip Code"
                        inputType={inputTypes.text}
                        id="ys-zip-code"
                        containerClass="address-input"
                        value={zipCode}
                        onChange={setZipCode}
                        errored={isInputErrored(zipCode)}
                        isRequired
                        showIsRequired={false}
                        isLoading={isLoading}
                    />
                </FormFieldSetLegend>
                <FormInputLabel
                    label="Additional Comments"
                    inputType={inputTypes.textarea}
                    id="ys-notes"
                    labelClass="additional-comments-label"
                    value={comments}
                    onChange={setComments}
                    errored={isInputErrored(comments)}
                    isLoading={isLoading}
                    showLabelAbove
                />
                <div className="recaptcha-container">
                    <ReCAPTCHA ref={recaptchaRef} sitekey={config.sitekey} onChange={onRecaptchaChange} />
                </div>
                <input type="submit" disabled={isLoading} className="submit-button" value="Submit" onClick={handleSubmitForm} />
            </div>
        </form>
        <ToastContainer
            position={`${isMobile ? 'bottom-center' : 'bottom-right'}`}
            autoClose={5000}
            className="toast-messages"
        />
    </>;
};

const FormFieldSetLegend = ({ label, id, legendClass, fieldsetClass, ariaLabel = '', children, isRequired = false, showIsRequired = false, isOneRequired = false }) => {
    return <>
        <legend htmlFor={id} className={`legend-style ${legendClass ?? ''}`} aria-required={isRequired} aria-label={ariaLabel}>{label}{showIsRequired && <span className="required">{`${isOneRequired ? ' (at least one required)' : ' (required)'}`}</span>}</legend>
        <fieldset id={id} className={`fieldset-style ${fieldsetClass ?? ''}`}>
            {children}
        </fieldset>
    </>;
};

const FormInputLabel = ({ label, inputType, id, labelClass, inputClass, containerClass, placeholder, children, isLoading = false, errored = false, value = null, onChange = null, isRequired = false, showIsRequired = false, showLabelAbove = false }) => {
    const onChangeHandler = (val) => {
        if (onChange) {
            onChange(val.target.value);
        }
    };

    const getInput = () => {
        switch (inputType) {
            case inputTypes.text:
                return <input type="text" disabled={isLoading} id={id} className={`text-input ${inputClass ?? ''} ${isRequired && errored ? 'errored' : ''}`} defaultValue={value} required={isRequired} placeholder={placeholder} onChange={onChangeHandler} />;
            case inputTypes.email:
                return <input type="email" disabled={isLoading} id={id} className={`text-input ${inputClass ?? ''} ${isRequired && errored ? 'errored' : ''}`} defaultValue={value} required={isRequired} placeholder={placeholder} onChange={onChangeHandler} />;
            case inputTypes.telephone:
                return <input type="tel" disabled={isLoading} id={id} className={`text-input ${inputClass ?? ''} ${isRequired && errored ? 'errored' : ''}`} defaultValue={value} required={isRequired} placeholder={placeholder} onChange={onChangeHandler} />;
            case inputTypes.select:
                return <select id={id} disabled={isLoading} className={`select-input ${inputClass ?? ''} ${isRequired && errored ? 'errored' : ''}`} defaultValue={value} required={isRequired} onChange={onChangeHandler}>{children}</select>;
            case inputTypes.textarea:
                return <textarea id={id} disabled={isLoading} className={`textarea-input ${inputClass ?? ''} ${isRequired && errored ? 'errored' : ''}`} defaultValue={value} required={isRequired} placeholder={placeholder} onChange={onChangeHandler} />;
            default:
                throw new Error(`Input of type '${inputType}' is not supported.`);
        }
    };

    return <span className={`input-label-container ${containerClass ?? ''}`}>
        {!showLabelAbove && getInput()}
        <label htmlFor={id} className={`${labelClass ?? ''}${isRequired && errored ? ' errored--label' : ''}`} aria-required={isRequired}>{label}{showIsRequired && <span className="required"> (required)</span>}</label>
        {showLabelAbove && getInput()}
    </span>;
};

export default YardSignForm;