import { ReactComponent as Logo } from '../images/KeithForAurora-v2-long-transparent.svg';
import { Link } from 'react-router-dom';

const Footer = () => {
    return <>
        <div className="footer-container">
            <div className="footer--content">
                <b>Paid for by Citizens for Keith Larson</b><br />
                <a className="footer--link" href="mailto:keithforaurora@gmail.com">keithforaurora@gmail.com</a><br />
                <a className="footer--link" href="tel:+16304920276">(630) 492-0276</a>
            </div>
            <div className="yard-sign-link--container">
                <b>Request a Yard Sign!</b>
                <span>If you want to help me build name recognition, click the button below to request a yard sign.</span>
                <Link className="yard-sign-link--button" to="/contact#yard-sign-information">Get a Yard Sign</Link>
            </div>
            <div className="img--container">
                <Logo className="footer--logo_img"
                    //src={require('../images/KeithForAurora-logo-long-transparent.png')}
                    alt="Keith Larson for Aurora Alderman At Large"
                    aria-label="Logo and link to home page" />
            </div>
        </div>
    </>;
};

export default Footer;