import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

const HomePage = () => {
    const isMobile = useMediaQuery({ maxWidth: '1024px' });

    return <>
        <Helmet>
            <title>Keith for Aurora</title>
            <meta property="og:url" content="https://keithforaurora.com" />
            <meta property="og:title" content="Keith for Aurora" />
            <meta property="og:description" content="Keith Larson's website about his run for Alderman-at-Large in the April 1, 2025 election in Aurora, IL" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@keithforaurora" />
            <meta name="twitter:title" content="Keith for Aurora" />
            <meta name="twitter:description" content="Keith Larson's website about his run for Alderman-at-Large in the April 1, 2025 election in Aurora, IL" />
            <meta name="description" content="Keith Larson's website about his run for Alderman-at-Large in the April 1, 2025 election in Aurora, IL" />
        </Helmet>
        <h1>Why am I running?</h1>
        <div className="page-content--container_max-width">
            <p>
                I began attending city council meetings during this year to speak out for social justice and safety concerns
                of fellow Aurora residents. I quickly learned that the concerns of residents are not seen as a priority by the
                mayor and many Alderpeople. Instead, I saw a city run by a mayor who uses city council meetings to promote
                himself before ignoring many people who make public comment expressing reasonable concerns. Also, city council is
                guilty of rubber stamping pay-to-play contracts, giving subsidies to developers in exchange for campaign contributions
                to members of city council. Everyone is aware of the corruption that we know happens in Washington D.C. and
                Springfield, but it is happening in our own community.
            </p>
            <p>
                Ron Woerman is currently in the Alderman-at-Large seat I am running for and his ties to contractors with the city are
                a conflict of interest that needs to be called out and challenged. Alderman Woerman was an owner of Fox Valley Developers,
                which received millions in subsidies to redevelop the Old Copley hospital. His brother is also a contractor that does business
                with the city. Because of this, he has had to recuse himself from discussions during city council meetings frequently compared
                to the other Alderpeople. It is good that he recuses himself from decisions in meetings concerning contracts he has ties to,
                but we can only wonder what goes on behind closed doors that is not recorded in open meetings.
            </p>
            <p>
                I decided to run for Alderman-at-Large because I think we need ordinary working people who do not have conflicts of interest
                to be on the city council. I have no ties to any companies that do business with the city and I will never accept donations
                from anyone who would financially gain from decisions made by the city council.
            </p>
            <div className="home-page--video-container">
                <iframe width={isMobile ? '100%' : '560'} height="315" src="https://www.youtube.com/embed/uJCo_jemHiQ?si=VoiO2ES3Y7q2Dj6C" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <h1>Get Involved</h1>
            <p>
                I recently received my order of yard signs! Because I don't take money from special interests, yard signs
                are the best way for me to build name recognition and let people around the city know I am running.
                Unlike Team Irvin, I do not put up signs on public property and I only put up signs with consent. If you
                would like to help me out by putting up a sign in your yard, click on the button below to request a sign
                and we will come out soon and put it in your yard.
            </p>
            <Link className="yard-sign-link-home--button" to="/contact#yard-sign-information">Get a Yard Sign</Link>
        </div>
    </>;
};

export default HomePage;